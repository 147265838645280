import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeContext } from '../context/ThemeContext';
import _ from 'lodash';

import { safePrefix } from '../utils';
import Header from './Header';
import Footer from './Footer';

function Body(props) {
    const { theme, themeToggle } = useContext(ThemeContext);
    useEffect(() => {
        async function loadHighliter() {
            try {
                const deckdeckgoHighlightCodeLoader = require('@deckdeckgo/highlight-code/dist/loader');

                await deckdeckgoHighlightCodeLoader.defineCustomElements(
                    window
                );
            } catch (err) {
                console.error(err);
            }
        }
        loadHighliter();
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {_.get(props, 'pageContext.frontmatter.title') &&
                        _.get(props, 'pageContext.frontmatter.title') + ' - '}
                    {_.get(props, 'pageContext.site.siteMetadata.title')}
                </title>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta
                    name="description"
                    content={_.get(
                        props,
                        'pageContext.site.siteMetadata.header.meta_description'
                    )}
                />
                <meta name="google" content="notranslate" />
                <meta
                    property="og:url"
                    content={_.get(
                        props,
                        'pageContext.site.siteMetadata.siteUrl'
                    )}
                />
                <meta
                    property="og:title"
                    content={
                        _.get(props, 'pageContext.frontmatter.title') ||
                        _.get(props, 'pageContext.site.siteMetadata.title')
                    }
                />
                <meta
                    property="og:description"
                    content={
                        _.get(props, 'pageContext.frontmatter.subtitle') ||
                        _.get(
                            props,
                            'pageContext.site.siteMetadata.header.meta_description'
                        )
                    }
                />
                <meta
                    property="og:image"
                    content={
                        _.get(props, 'pageContext.site.siteMetadata.siteUrl') +
                            _.get(
                                props,
                                'pageContext.frontmatter.content_img_path'
                            ) ||
                        _.get(props, 'pageContext.site.siteMetadata.siteUrl') +
                            _.get(
                                props,
                                'pageContext.site.siteMetadata.header.meta_img'
                            )
                    }
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="laurenclark.io" />
                <meta name="twitter:creator" content="@pantone_369" />
                <meta
                    name="twitter:title"
                    content={
                        _.get(props, 'pageContext.frontmatter.title') ||
                        _.get(props, 'pageContext.site.siteMetadata.title')
                    }
                />
                <meta
                    name="twitter:description"
                    content={
                        _.get(props, 'pageContext.frontmatter.subtitle') ||
                        _.get(
                            props,
                            'pageContext.site.siteMetadata.header.meta_description'
                        )
                    }
                />
                <meta
                    name="twitter:image"
                    content={
                        _.get(props, 'pageContext.site.siteMetadata.siteUrl') +
                            _.get(
                                props,
                                'pageContext.frontmatter.content_img_path'
                            ) ||
                        _.get(props, 'pageContext.site.siteMetadata.siteUrl') +
                            _.get(
                                props,
                                'pageContext.site.siteMetadata.header.meta_img'
                            )
                    }
                />
                <link
                    id="themeColor"
                    rel="stylesheet"
                    href={safePrefix(
                        `assets/css/${!theme ? 'main' : theme}.css`
                    )}
                />
                {_.get(props, 'pageContext.frontmatter.template') === 'post' &&
                    _.get(props, 'pageContext.frontmatter.canonical_url') && (
                        <link
                            rel="canonical"
                            href={_.get(
                                props,
                                'pageContext.frontmatter.canonical_url'
                            )}
                        />
                    )}
            </Helmet>
            <div
                id="page"
                className={
                    'site style-' +
                    _.get(props, 'pageContext.site.siteMetadata.layout_style') +
                    ' palette-' +
                    _.get(props, 'pageContext.site.siteMetadata.palette') +
                    ' ' +
                    theme
                }>
                <Header {...props} theme={theme} themeToggle={themeToggle} />
                <div id="content" className="site-content">
                    <div className="inner">
                        <main id="main" className="site-main">
                            {props.children}
                        </main>
                        <Footer {...props} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Body;
