import ContentBlock from './ContentBlock';
import Footer from './Footer';
import Header from './Header';
import HeroBlock from './HeroBlock';
import PostsBlock from './PostsBlock';
import Social from './Social';
import Layout from './Layout';

export { ContentBlock, Footer, Header, HeroBlock, PostsBlock, Social, Layout };

export default {
    ContentBlock,
    Footer,
    Header,
    HeroBlock,
    PostsBlock,
    Social,
    Layout
};
